import React from "react";
import {Avatar, Checkbox} from "antd";

function convertHtmlToText(str)
{
  str = str.toString();
  return str.replace(/<[^>]*(>|$)|"|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt|&lt|&amp|&quot|&#039;/g, ' ');
}
const MailListItem = ({mail, onMailSelect, onMailChecked, onStartSelect, labels}) => {
  return (
    <div className={mail.read === true ? "gx-module-list-item gx-mail-cell read-mail" : "gx-module-list-item gx-mail-cell"}>
      <div className="gx-module-list-icon">
        <Checkbox color="primary" className="gx-icon-btn"
                  checked={mail.selected}
                  onClick={(event) => {
                    event.stopPropagation();
                    onMailChecked(mail)
                  }}
                  value="SelectMail"
        />
        <div onClick={() => {
          onStartSelect(mail);
        }}>
          {mail.starred ?
            <i className="gx-icon-btn icon icon-star"/> :
            <i className="gx-icon-btn icon icon-star-o"/>
          }

        </div>

        <div className="gx-ml-2">
          {
          (mail.folderM === 1 || mail.folderM === 2) ? mail.folderM === 1 ?
          <Avatar className="gx-avatar gx-bg-blue gx-size-40"> {mail.to[0].name.charAt(0).toUpperCase()}</Avatar> :
          <Avatar className="gx-size-40" alt={mail.to[0].name}
                  src={mail.to[0].avatar}/>
          : mail.from.avatar === '' ?
            <Avatar className="gx-avatar gx-bg-blue gx-size-40"> {mail.from.name.charAt(0).toUpperCase()}</Avatar> :
            <Avatar className="gx-size-40" alt={mail.from.name}
                    src={mail.from.avatar}/>
          }

          <span className="gx-toolbar-separator sender-dm">&nbsp;</span>
          <span className="gx-sender-name sender-dm">{ (mail.folderM === 1 || mail.folderM === 2) ? "To: " + mail.to[0].name : mail.from.name}</span>
        </div>
      </div>

      <div className="gx-mail-list-info" onClick={() => {
        onMailSelect(mail);
      }}>

        <div className="gx-module-list-content">
          <div className="gx-mail-user-des">
          <div className="sender-dd">
            <span className="gx-sender-name">{ (mail.folderM === 1 || mail.folderM === 2) ? "To: " + mail.to[0].name : mail.from.name}</span>
            <span className="gx-toolbar-separator">&nbsp;</span>
          </div>
            <span className="gx-d-inline-block gx-text-truncate gx-send-subject">{mail.subject}</span>

            {mail.hasAttachments &&

            <i className="icon icon-attachment"/>}

            <div className="gx-time">{mail.time}</div>

          </div>


          <div className="gx-message">
            <p className="gx-text-truncate">{convertHtmlToText(mail.message)}</p>

          </div>
          <div className="gx-labels">
            {labels && labels.map((label, index) => {
              return (mail.labels).includes(label.labelId) &&
                <div key={index} className={`gx-badge gx-text-white gx-bg-green`}>{label.labelName}</div>
            })}
          </div>
        </div>

      </div>

    </div>
  )
};

export default MailListItem;
