import React from "react";
import {Avatar, Dropdown, Menu, Input,message, Button, Select} from "antd";
import CustomScrollbars from 'util/CustomScrollbars';
import ComposeMail from "../Compose/compose";
import ConversationCell from "./ConversationCell";

const {TextArea} = Input;
const Option = Select.Option;
const options = [
  'Reply',
  'Forward',
  'Delete'
];
const units = ['bytes', 'KB', 'MB', 'GB'];

class MailDetail extends React.Component {

  _handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.submitComment();
    }
  };

  state = {
    showDetail: false
  };

  constructor(props) {
    super(props);
    // const {conversation} = props;
    this.myRef = React.createRef();
    this.state = {
      replyMessage: '',
      user_id: '',
      conversation: this.props.mail.replyMessage,
      fileInfos: [],
      uploadFile: '',
      uploadingFile: false,
      showComposeMail: false,
      showReplyDiv: false,
      users: [],
      composeType: 0,
      mailAuthKey: null
    }
    this.showCompose = this.showCompose.bind(this);
  }

  forwardCompose = () => {
    this.setState({composeType: 1});
    this.showCompose();
  }   

  niceBytes(x){
    let l = 0, n = parseInt(x, 10) || 0;
    while(n >= 1024 && ++l){
        n = n/1024;
    }
    return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
  }

  showCompose() {
    this.setState(prevState => ({
      showComposeMail: !prevState.showComposeMail
    }));
  }

  componentDidMount() {
    if(this.props.mail.read === false){
      this.props.readMail(this.props.mail);
    }
    const userid = localStorage.getItem('user_id');
    const mailAuthKey = localStorage.getItem('mailAuthKey');
    var {toss} = "";
    var tos = [];
    if (this.props.mail) {
      if (this.props.mail.folder === 1) {
        if (this.props.mail.to && this.props.mail.to.length > 0) {
          if (this.props.mail.to[0].name !== "") {
            toss = this.props.mail.to.map(to => to.name).join(", ");
            tos = toss.split(", ");
          }
        }
      } else {
            tos = [this.props.mail.from.name];
      }
    }
    this.setState({ user_id: userid, tos: tos, mailAuthKey: mailAuthKey});
    fetch('https://manage.phoenixads.net/mailbox/get-user-lists.php')
      .then(response => response.json())
      .then(result => {
        this.setState({users:result.user});
      })
  }
  
  submitComment() {
    if (this.state.replyMessage !== '') {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "text/plain");
      var replycomment = {};
      replycomment.fromId = this.state.user_id;
      replycomment.mailAuthKey = this.state.mailAuthKey;
      replycomment.to = this.state.tos;
      replycomment.mailId = this.props.mail.id;
      replycomment.message = this.state.replyMessage;
      replycomment.mailAttachments = this.state.fileInfos;
      var raw = JSON.stringify(replycomment);
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      fetch("https://manage.phoenixads.net/mailbox/reply-mail-send.php", requestOptions)
        .then(response => response.text())
        .then(result => {
          const resp = JSON.parse(result);
          if(resp.status === "success"){
            const updatedConversation = this.state.conversation.concat(resp.data);
            this.setState({
              replyMessage: '',
              conversation: updatedConversation,
              fileInfos: []

            });
            message.success(`Message sent successfully.`);
          } else {
            message.error(`Something went wrong.`);
          }            
        })
        .catch(error => console.log('error', error));
    } else {
      message.error(`Invaild values.`);
    }
  }

  updateMessageValue(evt) {
    this.setState({
      replyMessage: evt.target.value
    });
  }
  updateUsersList(value){
    this.setState({
      tos: value
    })
  }

  replyToAll = () => {
    if (this.state.showReplyDiv) {
      this.setState({
        showReplyDiv: false
      })
    } else {
      this.setState({
        showReplyDiv: true
      })
      this.myRef.current.scrollIntoView({behavior: 'smooth',
      block: 'center',
      inline: 'center',});
    }
  }

  onDeleteMail = (mail) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");
    var API_Url = "";
    var raw = JSON.stringify({"userId":this.state.user_id,"mailLogId":mail.mailLogId,"mailAuthKey":this.state.mailAuthKey});
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw
    };
    if(mail.folder === 3){
      API_Url = "https://manage.phoenixads.net/mailbox/delete-mail.php"
    } else {
      API_Url = "https://manage.phoenixads.net/mailbox/move-trash-mail.php";
    }
    fetch(API_Url, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.statusCode === "200") {
          message.success("Mail Deleted Successfully.");
          this.props.onSelectDelete(mail);
        } else {
          message.error("Something Went Wrong.");
        }
    });
  }

  replyForward = (evt) => {
    var mType = evt.key;
    if(mType === "Reply") {
      this.setState({
        showReplyDiv: true
      })
      this.myRef.current.scrollIntoView({behavior: 'smooth',
      block: 'center',
      inline: 'center',});
    } else if(mType === "Delete"){
      this.onDeleteMail(this.props.mail);
    } else {
      this.showCompose()
    }
  }

  optionMenu = () => {
    return (
      <Menu id="long-menu">
        {options.map(option =>
          <Menu.Item key={option} onClick={this.replyForward}>
            {option}
          </Menu.Item>,
        )}
      </Menu>)
  };

  getFileExt(filepath){
      var fileType =  filepath.split("?")[0].split("#")[0].split('.').pop();
      var isImage = "";
      if(fileType === "jpg" || fileType === "jpeg" || fileType === "png") {
        isImage = "yes";
      } else {
        isImage = "no";
      }
      return isImage;
  }
  selectFile = (e) => {
    this.setState({uploadingFile: true});
    let { fileInfos } = this.state;
    var formdata = new FormData();
    formdata.append("sendimage", e.target.files[0]);
    var requestOptions = {
      method: 'POST',
      body: formdata
    };
    fetch("https://manage.phoenixads.net/mailbox/upload-file.php", requestOptions)
      .then(response => response.text())
      .then(result => {
        const resp = JSON.parse(result);
        if(resp.status === true){
          fileInfos.push(resp.data);
          this.setState({
            fileInfos: fileInfos,
            uploadFile: ''
          });
          message.success(resp.message);
        } else {
          message.error(resp.message);
        }
        this.setState({
          uploadingFile: false
        });
      })
      .catch(error => console.log('error', error));
  };

  render() {
    const {mail, onStartSelect, onImportantSelect, labels} = this.props;
    const {replyMessage, conversation, fileInfos, uploadFile, uploadingFile, showReplyDiv, users} = this.state;
    return (
      <div>
        {this.state.showComposeMail ? <ComposeMail mail={mail} type={this.state.composeType}/> : 
        <div className="gx-module-detail gx-mail-detail">
          <CustomScrollbars className="gx-module-content-scroll">
            <div className="gx-mail-detail-inner">
              <div className="gx-mail-user-info gx-ml-0 gx-mb-3">
                <div className="d-flex w-user right-border">
                  {mail.from.avatar === '' ?
                    <Avatar
                      className="gx-avatar gx-bg-blue gx-size-40 gx-mr-3"> {mail.from.name.charAt(0).toUpperCase()}</Avatar> :
                    <Avatar className="gx-size-40 name-profile-img gx-mr-3" alt={mail.from.name}
                            src={mail.from.avatar}/>
                  }
                  <div className="gx-sender-name">{mail.from.name}
                    <div className="gx-send-to gx-text-grey"><small>to {mail.to.map((to, index) => <span key={index}>{index > 0 && ', '} {to.name}</span>)}</small></div>
                    {mail.cc && mail.cc[0].name ?
                  <div><small><span className="gx-text-black"> CC: </span><span className="gx-send-to gx-text-grey">{mail.cc.map((cc, index) => <span key={index}>{index > 0 && ', '} {cc.name}</span>)
                    }</span></small></div> : null}
                  </div>
                </div>

                <div className="d-flex w-subject">  
                  <div className="w-actions-1 gx-col gx-pl-0">
                    <div className="gx-subject">
                      {mail.subject}
                    </div>

                    <div className="gx-labels">
                      {labels && labels.map((label, index) => {
                        return (mail.labels).includes(label.labelId) && <div key={index}
                                                                        className={`gx-badge gx-text-white gx-bg-green`}>{label.labelName}</div>
                      })}
                    </div>
                  </div>

                  <div className="gx-mail-header-actions">

                  <div onClick={() => {
                    onStartSelect(mail);
                  }}>
                    {mail.starred ?
                      <i className="icon icon-star gx-icon-btn"/> :
                      <i className="icon icon-star-o gx-icon-btn"/>
                    }

                  </div>
                  <div onClick={() => {
                    onImportantSelect(mail);
                  }}>

                    {mail.important ?
                      <i className="icon icon-important gx-icon-btn"/>
                      : <i className="icon icon-important-o gx-icon-btn"/>
                    }
                  </div>
                </div>
                  {mail.folder === 2 ? null :<Dropdown trigger={['click']} overlay={this.optionMenu()}>
                    <span className="gx-ml-auto"><i className="icon icon-ellipse-v gx-icon-btn"/></span>
                  </Dropdown> }
                  {
                    mail.folder === 2 ? <Button className="gx-btn-cyan" onClick={this.showCompose}>Open</Button> : null
                  }
                </div>
              </div>

              <div className="gx-show-link" onClick={() => {
                this.setState({showDetail: !this.state.showDetail});
              }}>{this.state.showDetail ? 'Hide Detail' : 'Show Detail'}</div>
              {this.state.showDetail && (<div className="gx-show-detail">
                <div><strong>Date: </strong>{mail.time} </div>
                <div><strong>Size: </strong>{this.niceBytes(mail.mailSize)} </div>
              </div>)}

              <hr/>    

              <div className="message-details" dangerouslySetInnerHTML={{__html: mail.message}} />

              {mail.hasAttachments &&
              <div className="gx-attachment-block mt-10">
                <h3>Attachments ({mail.attachments.length})</h3>
                <div className="gx-attachment-row">
                  {mail.attachments.map((attachment, index) =>
                    <div className="gx-attachment-col" key={index}>
                      <a href={attachment.url} download={attachment.fileName} target={this.getFileExt(attachment.fileName) === "yes" ? "_blank" : ""}>{attachment.fileName}</a>
                      <div className="size">{attachment.size}</div>
                    </div>
                  )}
                </div>
              </div>
              }
            </div>
            {conversation && conversation[0] !== '' && conversation.length > 0 && conversation.map((conversation, index) =>
              <ConversationCell key={index} conversation={conversation}/>
            )}
            {mail.folder === 2 ? null :
            <div className="gx-chat-main-footer gx-mail-main-footer" ref={this.myRef}>
              <div className="ant-row-flex gx-mb-3 gx-mb-md-2">
                <Button className="gx-mr-2" type="primary" onClick={this.replyToAll}><i className="icon icon-reply mr-5"/>  Reply</Button>
                <Button className="gx-mr-2" type="primary" onClick={this.showCompose}><i className="icon icon-sent mr-5"/> Reply All</Button>
                <Button className="gx-btn-cyan" onClick={this.forwardCompose}><i className="icon icon-forward mr-5"/> Forward</Button>
                <Button className="gx-btn-danger" onClick={() => {this.onDeleteMail(this.props.mail) }}><i className="icon icon-trash mr-5"/> Delete</Button>
              </div>
              {showReplyDiv ?
              <div>
                <div className="gx-align-items-center">
                  <div className="gx-col">
                    <div className="gx-form-group">
                     <Select 
                     mode="multiple" 
                     placeholder="Recipients" 
                     defaultValue={this.state.tos} 
                     onChange={this.updateUsersList}
                     className="sreply">
                        {
                          users.map(users => (
                            <Option key={users.name}>{users.name}</Option>
                          ))
                        }
                      </Select>
                      <TextArea className="gx-border-0 ant-input gx-chat-textarea"
                          id="required"
                          onKeyUp={this._handleKeyPress.bind(this)}
                          onChange={this.updateMessageValue.bind(this)}
                          value={replyMessage}
                          rows={2}
                          placeholder="Type and hit enter to send message"/>
                    </div>
                  </div>
                </div>
                <div className="gx-flex-row gx-align-items-center mt-10">
                  <div className="gx-col">
                    <div>
                    <Input type="file" value={uploadFile} onChange={this.selectFile} />
                    </div>
                    {uploadingFile ? <Button type="primary" size="small" loading>
                      Uploading ...
                    </Button> : null }
                  </div>
                  <div className="gx-col">
                    <div className="gx-mail-sent"
                        onClick={this.submitComment.bind(this)}
                        aria-label="Send message">
                      <i className="gx-icon-btn icon icon-sent"/>
                    </div>
                  </div>
                </div>
                <div className="card file-uploads">
                  <ul className="list-group">
                    {fileInfos &&
                      fileInfos.map((file, index) => (
                        <li className="list-group-item" key={index}>
                          <a href="/#" rel="noopener noreferrer">{file.fileName}</a>
                        </li>
                      ))}
                  </ul>    
                </div>
              </div> : null}
          </div> }
          </CustomScrollbars>
        </div> }
      </div>
    );
  }
}

export default MailDetail;
