import React from "react";
import CircularProgress from "components/CircularProgress/index";
import {Link} from "react-router-dom";

class Welcome extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {loader} = this.props;
    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
              </div>
              <div className="gx-app-logo">
                <img alt="phoenix" src={require("assets/images/phoenix-text.png")}/>
              </div>
              <div className="gx-app-logo-wid">
                <h1>Phoenix Mail</h1>
                <p>By Manage Login, you can avail full features of our services.</p>
                <p>Please login via manage.</p>
              </div>
              
            </div>
            <div className="gx-app-login-content">
              <h1 className="h1-black">Welcome!</h1>
              <p>It is hard to check five email inboxes, three voice mail systems, or five blogs that you are tracking.</p>
              <Link to={{ pathname: "https://manage.phoenixads.net" }} target="_blank" className="goManage">Go To Manage</Link>
            </div>

            {loader ?
              <div className="gx-loader-view">
                <CircularProgress/>
              </div> : null}
          </div>
        </div>
      </div>
    );
  }
}


export default Welcome;