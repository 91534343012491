import React from "react";
import {Button, message, Popover} from "antd";
var myHeaders = new Headers();
myHeaders.append("Content-Type", "text/plain");

class AppModuleHeader extends React.Component {
  state = {
    visible: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      username: ''
    };
  }

  componentDidMount() {
    const username = localStorage.getItem('username');
    this.setState({ username: username });
    document.title = username+' | Phoenix';
  }
  
  userLogOut = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('user_id');
    message.error("Logout Succesfully.");
    window.location.href = "/"
  }

  handleVisibleChange = (visible) => {
    this.setState({visible});
  };

  render() {
    const {placeholder, onChange, onKeyPress, value} = this.props;
    var {username} = this.state;
    return (
      <div className="gx-module-box-header-inner">
        <div
          className="gx-search-bar gx-lt-icon-search-bar-lg gx-module-search-bar gx-d-none gx-d-sm-block">
          <div className="gx-form-group">
            <input className="ant-input gx-border-0" type="search" placeholder={placeholder}
                   onChange={onChange}
                   onKeyPress={onKeyPress}
                   value={value}/>
            <span className="gx-search-icon gx-pointer"><i className="icon icon-search"/></span>
          </div>
        </div>
        <div className="gx-module-box-header-right">
        <Popover content={<Button type="primary" onClick={this.userLogOut}>Logout</Button>} title={username} trigger="click"
          visible={this.state.visible}
          onVisibleChange={this.handleVisibleChange} className="pop-mt">
          <Button>{username}</Button>
        </Popover>
        </div>
      </div>
    )
  }
}

export default AppModuleHeader;

AppModuleHeader.defaultProps = {
  styleName: '',
  value: '',
  notification: true,
  apps: true,
};
