import React from "react";
import {Form, message, Input, Button} from "antd";
import {connect} from "react-redux";
import CircularProgress from "components/CircularProgress/index";
const FormItem = Form.Item;
class SignIn extends React.Component {

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        var raw = JSON.stringify(values);
        var requestOptions = {
          method: 'POST',
          body: raw
        };

        fetch("https://manage.phoenixads.net/mailbox/user-login.php", requestOptions)
          .then(response => response.json())
          .then(result => {
            if(result.status === "Success") {
              message.success("login successfully");
              localStorage.setItem('user_id', result.userId);
              localStorage.setItem('username', result.name);
              localStorage.setItem('mailAuthKey', result.mailAuthKey);
              window.location.href = "/";
            } else if(result.status === "notLogin"){
              message.error("Please login first manage.");
            } 
            else {
              message.error("Invalid login details.");
            }
          })
          .catch(error => console.log('error', error));
      }
    });
  };

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
  }

  render() {
    const {showMessage, loader, alertMessage} = this.props;
    const {getFieldDecorator} = this.props.form;
    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
              </div>
              <div className="gx-app-logo">
                <img alt="phoenix" src={require("assets/images/phx-logo.png")}/>
              </div>
              <div className="gx-app-logo-wid mt-10">
                <p>By Manage Login, you can avail full features of our services.</p>
                <p>Please login via manage.</p>
              </div>
              
            </div>
            <div className="gx-app-login-content">
              <h1>Phoenix Messages</h1>
            <Form onSubmit={this.handleSubmit} className="gx-signup-form gx-form-row0">
                <FormItem>
                  {getFieldDecorator('username', {
                    rules: [{required: true, message: 'Please enter your username!'}],
                  })(
                    <Input placeholder="Username"/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    rules: [{required: true, message: 'Please enter your Password!'}],
                  })(
                    <Input type="password" placeholder="Password"/>
                  )}
                </FormItem>
                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    Login
                  </Button>
                </FormItem>
              </Form>
            </div>

            {loader ?
              <div className="gx-loader-view">
                <CircularProgress/>
              </div> : null}
            {showMessage ?
              message.error(alertMessage.toString()) : null}
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({auth}) => {
  const {loader, alertMessage, showMessage, authUser} = auth;
  return {loader, alertMessage, showMessage, authUser}
};

export default connect(mapStateToProps, {
})(WrappedNormalLoginForm);
