import React from "react";
import {Avatar} from "antd";

const ConversationCell = ({conversation}) => {
  return (
    <div className="reply-blocks">
      <hr/> 
      <div className="gx-flex-row gx-module-detail-item gx-flex-nowrap">
        <div className="gx-chat-todo-avatar">

          <Avatar className="gx-rounded-circle gx-size-40" src={conversation.from.avatar}
                  alt="..."/>
        </div>
        <div className="gx-chat-toto-info">
          <div className="gx-flex-column">
            <div className="gx-name gx-mr-2">{conversation.from.name}</div>
            <div className="gx-time gx-text-muted">{conversation.time}</div>
          </div>
          <div className="gx-message">{conversation.message.replace(/\\n/g, "\n")}</div>
        </div>
      </div>
      {conversation.hasAttachments &&
      <div className="gx-attachment-block mails-attachment">
        <h4>Attachments ({conversation.attachments.length})</h4>
        <div className="gx-attachment-row">
          {conversation.attachments.map((attachment, index) =>
            <div className="gx-attachment-col" key={index}>
              <a href={attachment.url} target="_blank" rel="noopener noreferrer">{attachment.fileName}</a>
              <div className="size">{attachment.size}</div>
            </div>
          )}
        </div>
      </div>
      }
    </div>
  )
};

export default ConversationCell;
