export default [
  {
    'labelId': "L1",
    'labelName': 'OTP'
  },
  {
    'labelId': "L2",
    'labelName': 'Leave Requests'
  },
  {
    'labelId': "L3",
    'labelName': 'Asset Related'
  },
  {
    'labelId': "L4",
    'labelName': 'Compliance'
  },
  {
    'labelId': "L5",
    'labelName': 'Finance'
  }
];
