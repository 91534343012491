import React from "react";
import CKEditor from "react-ckeditor-component";
import {Button, Form, Input, message, Select} from "antd";
const FormItem = Form.Item;
const Option = Select.Option;

var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
var myHeaders = new Headers();
myHeaders.append("Content-Type", "text/plain");
class ComposeMailForm extends React.Component {
  constructor(props) {
    super(props);
    this.updateContent = this.updateContent.bind(this);
    this.state = {
        users:[],
        user_id: '',
        mailUniqueKey: '',
        fileInfos: [],
        uploadFile: '',
        uploadingFile: false,
        mailAuthKey: null,
        messageContent: ''
    };
  }
  componentDidMount() {
    var mailUniqueKey = '';
    const userid = localStorage.getItem('user_id');
    const mailAuthKey = localStorage.getItem('mailAuthKey');
    this.setState({ user_id: userid, mailAuthKey: mailAuthKey });
    fetch('https://manage.phoenixads.net/mailbox/get-user-lists.php')
      .then(response => response.json())
      .then(result => {
        this.setState({users:result.user});
    })
    
    if(this.props.mail && this.props.mail.folder === 2){
      mailUniqueKey = this.props.mail.id;
    } else {
      for ( var i = 0; i < 20; i++ ) {
        mailUniqueKey += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
      }
    }
    this.setState({mailUniqueKey: mailUniqueKey});
    if(this.props.mail){
      this.setState({messageContent: "</br><b>----- Original Message ----</b></br>FROM: "+this.props.mail.from.name+"</br>TO: "+this.props.mail.to.map((to) =>to.name)+"</br>Date:"+this.props.mail.time+"</br></br>"+this.props.mail.message});
      if(this.props.mail.hasAttachments === true){
        this.setState({fileInfos: this.props.mail.attachments})
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.handleChange();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.from = this.state.user_id;
        values.mailAuthKey = this.state.mailAuthKey;
        values.id = this.state.mailUniqueKey;
        var raw = JSON.stringify(values);
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        fetch("https://manage.phoenixads.net/mailbox/insert-mail-send.php", requestOptions)
          .then(response => response.text())
          .then(result => {
            const resp = JSON.parse(result);
            if(resp.status === "success"){
              message.success(`Mail sent successfully`);
              setTimeout(() => {
                window.location.reload(true);
              }, 500);
            } else {
              message.error(`Something went wrong.`);
            }            
          })
          .catch(error => console.log('error', error));
        }
    });
  };

  onMessageChange(evt) {
    const newContent = evt.editor.getData();
    this.setState({
      messageContent: newContent
    })
    this.updateContent(newContent);
  }

  updateContent(newContent) {
    this.setState({
      messageContent: newContent
    })
    this.handleChange();
  }
  handleChange = () => {
    this.props.form.validateFields((err, values) => {
      values.from = this.state.user_id;
      values.id = this.state.mailUniqueKey;
      values.mailAuthKey = this.state.mailAuthKey;
      values.mailAttachments = this.state.fileInfos;
      values.message = this.state.messageContent;
      var raw = JSON.stringify(values);
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      fetch("https://manage.phoenixads.net/mailbox/compose-mail-draft.php", requestOptions)
        .then(response => response.text())
        .then(result => {
          const resp = JSON.parse(result);
            if(resp.status !== "Success"){
              message.error(`Something went wrong.`);
            }
        })
        .catch(error => console.log('error', error));
    });
  };

  selectFile = (e) => {
    this.setState({uploadingFile: true});
    let { fileInfos } = this.state;
    var formdata = new FormData();
    formdata.append("sendimage", e.target.files[0]);
    var requestOptions = {
      method: 'POST',
      body: formdata
    };
    fetch("https://manage.phoenixads.net/mailbox/upload-file.php", requestOptions)
      .then(response => response.text())
      .then(result => {
        const resp = JSON.parse(result);
        if(resp.status === true){
          fileInfos.push(resp.data);
          this.setState({
            fileInfos: fileInfos,
            uploadFile: ''
          });
          this.handleChange();
          
          message.success(resp.message);
        } else {
          message.error(resp.message);
        }
        this.setState({
          uploadingFile: false
        });
      })
      .catch(error => console.log('error', error));
  };

  render() {
    const {mail, type} = this.props;
    var {users, fileInfos, uploadFile, uploadingFile} = this.state;
    const {getFieldDecorator} = this.props.form;
    const formItemLayout = {
      labelCol: {xs: 24, sm: 3},
      wrapperCol: {xs: 24, sm: 21},
    };
    var {ccs, tos, forms} = [];
    var {ccss, toss} = "";
    if (mail) {
      if (type === 0) {
        if (mail.cc && mail.cc.length > 0) {
          if (mail.cc[0].name !== "") {
            ccss = mail.cc.map(cc => cc.name).join(", ");
            ccs = ccss.split(", ");
          }
        }
        if(mail.folder === 0) {
          forms = mail.from.name;
          toss = mail.to.map(to => to.name).join(", ");
          tos = toss.split(", ");
          const isInArray = tos.includes(forms);
          if(!isInArray){
            tos.push(forms);
          }
        } else {
          if (mail.to && mail.to.length > 0) {
            if (mail.to[0].name !== "") {
              toss = mail.to.map(to => to.name).join(", ");
              tos = toss.split(", ");
            }
          }
        }
      }
    }
      return (
      <div className="composemail">
        <Form onSubmit={this.handleSubmit}>
        <FormItem
            {...formItemLayout}
            label="To"
          >
            {getFieldDecorator('to', {
              rules: [
                {required: true, message: 'Please select recipient', type: 'array'},
              ],
              initialValue: tos && tos.length > 0 ? tos : []
            })(
              <Select mode="multiple" placeholder="Recipients" onChange={this.handleChange}>
                {
                  users.map(users => (
                    <Option key={users.name}>{users.name}</Option>
                  ))
                }
              </Select>
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="CC"
          >
            {getFieldDecorator('cc', {
              rules: [
                {message: 'Recipients', type: 'array'},
              ],
              initialValue: ccs && ccs.length > 0 ? ccs : []
            })(
              <Select mode="multiple" placeholder="Recipients"  onChange={this.handleChange}>
                {
                  users.map(users => (
                    <Option key={users.name}>{users.name}</Option>
                  ))
                }
              </Select>
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Subject"
          >
            {getFieldDecorator('subject', {
              rules: [
                {required: true, message: 'Please enter your subject!'},
              ],
              initialValue: mail ? mail.subject : null 
            })(
              <Input
              placeholder="Subject"
              margin="normal"
              onChange={this.handleChange}
            />
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Message"
          >
            <CKEditor
              activeClass="p10"
              content={this.state.messageContent}
              events={{
                'change': this.onMessageChange.bind(this)
              }}
            />
          </FormItem>
          <FormItem 
            {...formItemLayout}
            label="Upload"
          >
              <Input type="file" value={uploadFile} onChange={this.selectFile} />
          </FormItem>
          {uploadingFile ? <Button type="primary" size="small" loading>
            Uploading ...
          </Button> : null }
          <div className="card">
              <ul className="list-group">
                {fileInfos &&
                  fileInfos.map((file, index) => (
                    <li className="list-group-item" key={index}>
                      <a href="/#" rel="noopener noreferrer">{file.fileName}</a>
                    </li>
                  ))}
              </ul>    
          </div>
          <FormItem
            wrapperCol={{xs: 24}}>
            <Button type="primary" htmlType="submit">Submit</Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}
const ComposeMail = Form.create()(ComposeMailForm);

export default ComposeMail;
