import React, {PureComponent} from "react";
import {Button, Checkbox, Drawer, Dropdown, Menu, message, Icon, Modal, Input, Form} from "antd";
import CustomScrollbars from "util/CustomScrollbars";
import folders from "./data/folders";
import filters from "./data/filters";
import options from "./data/options";
import fixlabels from "./data/labels";
import MailList from "components/mail/MailList";
import ComposeMail from "components/mail/Compose/compose";
import AppModuleHeader from "components/AppModuleHeader/index";
import MailDetail from "components/mail/MailDetail/index";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "../../components/CircularProgress/index";
import Auxiliary from "../../util/Auxiliary";
import ImageLogo from "../../assets/images/phoenix-text-1.png";
const ButtonGroup = Button.Group;
var myHeaders = new Headers();
myHeaders.append("Content-Type", "text/plain");
class Mail extends PureComponent {
  MailSideBar = () => {
    return <div className="gx-module-side">

      <div className="gx-module-side-header">
        <div className="gx-module-logo main-logo">
          <img src={ImageLogo} alt="Phoenix"/>
        </div>
      </div>

      <div className="gx-module-side-content">
        <CustomScrollbars className="gx-module-side-scroll">
          <div className="gx-module-add-task">
            <Button type="primary" className="gx-btn-block"
                    onClick={this.showCompose}>
              <i className="icon icon-edit gx-mr-2"/>
              <IntlMessages id="sidebar.mail.compose"/></Button>
          </div>

          <ul className="gx-module-nav">
            {this.getNavFolders()}

            <li className="gx-module-nav-label">
              <IntlMessages id="sidebar.mail.filters"/>
            </li>

            {this.getNavFilters()}

            <li className="gx-module-nav-label">
              <IntlMessages id="sidebar.mail.labels"/>
            </li>

            {this.getNavLabels()}
            <div className="gx-module-add-label">
              <Button type="primary" onClick={this.showModal}>My Labels</Button>
            </div>
          </ul>
        </CustomScrollbars>
      </div>
    </div>
  };

  setAddLabelmodal(addLabelmodal) {
    this.setState({addLabelmodal});
  }

  handleCancel = () => {
    this.setState({visible: false});
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handlechangeAddLabel = (addlabel) => {
    this.setState({addlabel: addlabel})
  }
  handleAddLabel = () => {
    this.setState({loading: true});
    const labelName = this.state.addlabel;
    var labels = [];
    var userLabels = [];
    var raw = JSON.stringify({userId: this.state.user_id, "mailAuthKey":this.state.mailAuthKey, labelName: labelName});
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch('https://manage.phoenixads.net/mailbox/create-mail-label.php', requestOptions)
      .then(response => response.json())
      .then(result => {
        this.setState({loading: false});
        if(result.status === "success") {
          if(this.state.userLabels){
            labels = this.state.labels.concat({labelId: result.mailId, labelName: labelName});
            userLabels = this.state.userLabels.concat({labelId: result.mailId, labelName: labelName});
          } else {
            userLabels = [{labelId: result.mailId, labelName: labelName}]
            labels = this.state.labels.concat({labelId: result.mailId, labelName: labelName});
          }
          message.success(`Label create successfully`);
          this.setState({addLabelmodal: false, addlabel: '', labels: labels, userLabels: userLabels});
        } else {
          message.warning(result.message);
        }
    })
  }

  deleteLabel(item){
    var raw = JSON.stringify({userId: this.state.user_id,"mailAuthKey":this.state.mailAuthKey, labelName: item.labelName});
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch('https://manage.phoenixads.net/mailbox/delete-label.php', requestOptions)
      .then(response => response.json())
      .then(result => {
        if(result.status === "success") {
          const labels = this.state.labels.filter(i => i.labelName !== item.labelName);
          const userLabels = this.state.userLabels.filter(i => i.labelName !== item.labelName)
          this.setState({labels: labels, userLabels: userLabels})
          message.success(`Label delete successfully`);
        } else {
          message.error(`Something went wrong.`);
        }
    })
  }

  onDeleteMail = () => {
    const mails = this.state.allMail.map(mail => {
        if (mail.selected && (mail.folder !== 99) && (mail.folder === this.state.selectedFolder)) {
          var API_Url = "";
          var raw = JSON.stringify({"userId":this.state.user_id,"mailAuthKey":this.state.mailAuthKey,"mailLogId":mail.mailLogId});
          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
          };
          if(this.state.selectedFolder === 3){
            API_Url = "https://manage.phoenixads.net/mailbox/delete-mail.php"
          } else {
            API_Url = "https://manage.phoenixads.net/mailbox/move-trash-mail.php";
          }
          fetch(API_Url, requestOptions)
            .then(response => response.json())
            .then(result => {
              if(result.status === "Success"){
                this.getfolderWithNum(mail.folder, "folder");
                this.setState({respSuccess: result.status});
              } else {
                message.error(result.message);
              }
          });
          return {...mail, folder: 4, selected: false};
        } else if(mail.selected && this.state.selectedFolder === 99){
          var raw1 = JSON.stringify({"userId":this.state.user_id,"mailAuthKey":this.state.mailAuthKey,"mailLogId":mail.mailLogId});
          var requestOptions1 = {
            method: 'POST',
            headers: myHeaders,
            body: raw1
          };
          fetch("https://manage.phoenixads.net/mailbox/move-trash-mail.php", requestOptions1)
            .then(response => response.json())
            .then(result => {
              if(result.status === "Success"){
                this.getfolderWithNum(mail.folder, this.state.selectLabelId);
              } else {
                message.error(result.message);
              }
          });
          return {...mail, folder: 3, selected: false};
        } else {
          return mail;
        }
      }
    )
    message.success("Mail Deleted Successfully.");
    this.setState({
      allMail: mails,
      folderMails: mails.filter(mail => mail.folder === this.state.selectedFolder)
    });
  };

  getNavFolders = () => {
    return folders.map((folder, index) =>
      <li key={index} onClick={() => {
        const mailLimit = this.state.initalPageNum+","+this.state.totalNumMail;
        this.setState({loader: true});
        this.getfolderWithNum(folder.id, "folder");
        var filterMails = "";
        var raw = JSON.stringify({"userId":this.state.user_id,"mailAuthKey":this.state.mailAuthKey,"folder":folder.id,"mailLimit":mailLimit});
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw
        };
        fetch('https://manage.phoenixads.net/mailbox/get-user-emails.php', requestOptions)
          .then(response => response.json())
          .then(result => {
            if(result.statusCode === 400){
              this.userLogOut();
            }else{  
            if(result.mails === null){
              filterMails = [];
            }else{
              filterMails = result.mails;
            }
            this.setState({
              selectedFolder: folder.id,
              noContentFoundMessage: 'No Mail found in selected folder',
              currentMail: null,
              loader: false,
              folderMails: filterMails,
              allMail: filterMails,
              mails: filterMails,
              showComposeMail: false,
              selectedFilter: 0
            });
          }
        })
      }
      }>
        <span className={`${this.state.selectedFolder === folder.id ? 'active gx-link' : 'gx-link'}`}>
          <i className={`icon icon-${folder.icon}`}/>
          <span className="labeltitle">{folder.title}</span>
        </span>
      </li>
    )
  };
  previousMail = () => {
    const currentMailPage = this.state.currentMailPage;
    const previousPage = currentMailPage - 1;
    const initalPageNum = this.state.initalPageNum; 
    if(previousPage === 0 && initalPageNum === 0) {
      this.setState({searchingMail: false});
    } else {
      var initalPageNumData = initalPageNum - this.state.totalNumMail;
      const mailLimit = initalPageNumData+","+this.state.totalNumMail;
      this.setState({loader: true});
      var filterMails = "";
      var raw = "";
      var selectedFolder = this.state.selectedFolder;
      var api_url = "";
      if(selectedFolder === 99) {
        api_url = "https://manage.phoenixads.net/mailbox/get-label-emails.php";
        raw = JSON.stringify({"userId":this.state.user_id,"mailAuthKey":this.state.mailAuthKey,"labelId":this.state.selectLabelId,"mailLimit":mailLimit});
      } else if(selectedFolder === 98) {
        api_url = "https://manage.phoenixads.net/mailbox/get-filter-emails.php";
        raw = JSON.stringify({"userId":this.state.user_id,"mailAuthKey":this.state.mailAuthKey,"filterType":this.state.selectedFilter,"mailLimit":mailLimit});
      } else {
        api_url = "https://manage.phoenixads.net/mailbox/get-user-emails.php";
        raw = JSON.stringify({"userId":this.state.user_id,"mailAuthKey":this.state.mailAuthKey,"folder":selectedFolder,"mailLimit":mailLimit});
      }
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw
      };
      fetch(api_url, requestOptions)
        .then(response => response.json())
        .then(result => {
          if(result.statusCode === 400){
            
            this.userLogOut();
          } else {
          if(result.mails === null){
            filterMails = [];
          }else{
            filterMails = result.mails;
          }
          this.setState({
            noContentFoundMessage: 'No Mail found in selected folder',
            currentMail: null,
            loader: false,
            folderMails: filterMails,
            allMail: filterMails,
            mails: filterMails,
            showComposeMail: false,
            currentMailPage: previousPage,
            initalPageNum: initalPageNumData,
            searchingMail: false
          });
        }
      })
    }
  }
  nextMail = () => {
    if(this.state.searchingMail === true) {
      this.setState({searchingMail: true});
    } else{
    var nextPage = this.state.currentMailPage + 1;
    var initalPageNum = this.state.totalNumMail * this.state.currentMailPage;
    const mailLimit = initalPageNum+","+this.state.totalNumMail;
    this.setState({loader: true});
      var filterMails = "";
      var raw = "";
      var selectedFolder = this.state.selectedFolder;
      var api_url = "";
      if(selectedFolder === 99) {
        api_url = "https://manage.phoenixads.net/mailbox/get-label-emails.php";
        raw = JSON.stringify({"userId":this.state.user_id,"mailAuthKey":this.state.mailAuthKey,"labelId":this.state.selectLabelId,"mailLimit":mailLimit});
      } else if(selectedFolder === 98) {
        api_url = "https://manage.phoenixads.net/mailbox/get-filter-emails.php";
        raw = JSON.stringify({"userId":this.state.user_id,"mailAuthKey":this.state.mailAuthKey,"filterType":this.state.selectedFilter,"mailLimit":mailLimit});
      } else {
        api_url = "https://manage.phoenixads.net/mailbox/get-user-emails.php";
        raw = JSON.stringify({"userId":this.state.user_id,"mailAuthKey":this.state.mailAuthKey,"folder":selectedFolder,"mailLimit":mailLimit});
      }
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw
      };
      fetch(api_url, requestOptions)
        .then(response => response.json())
        .then(result => {
          if(result.statusCode === 400){
            
            this.userLogOut();
          } else {
          if(result.mails === null){
            filterMails = [];
            this.setState({
              loader: false
            });
          }else{
            filterMails = result.mails;
            this.setState({
              noContentFoundMessage: 'No Mail found in selected folder',
              currentMail: null,
              loader: false,
              folderMails: filterMails,
              allMail: filterMails,
              mails: filterMails,
              showComposeMail: false,
              currentMailPage: nextPage,
              initalPageNum: initalPageNum,
              searchingMail: false
            });
          }
        }
      })
    }
  }
  onSelectDelete = (selectmail) => {
    const folderMails = this.state.folderMails.filter(mail => mail !== selectmail);
    const totalMails = this.state.totalMails - 1;
    this.setState({
      folderMails: folderMails,
      currentMail: null,
      totalMails: totalMails
    });
  }
  // onMailSend(data) {
  //   this.setState(
  //     {
  //       alertMessage: 'Mail Sent Successfully',
  //       showMessage: true,
  //       folderMails: this.state.allMail.concat(data),
  //       allMail: this.state.allMail.concat(data)
  //     }
  //   );
  // };

  onFolderMenuItemSelect = (e) => {
    const id = +e.key;
    const mails = this.state.allMail.map(mail => {
      if (mail.selected && (mail.folder === this.state.selectedFolder)) {
        var API_Url = "";
        var raw = JSON.stringify({"userId":this.state.user_id,"mailAuthKey":this.state.mailAuthKey,"mailLogId":mail.mailLogId});
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw
        };
        if(mail.folder === 3){
          API_Url = "https://manage.phoenixads.net/mailbox/trash-to-folder.php"
        } else {
          API_Url = "https://manage.phoenixads.net/mailbox/move-trash-mail.php";
        }
        fetch(API_Url, requestOptions)
          .then(response => response.json())
          .then(result => {
            if (result.statusCode === "200") {
              if (mail.folder === 99) {
                this.getfolderWithNum(mail.folder, this.state.selectLabelId);
              } else {
                this.getfolderWithNum(mail.folder, "folder");
              }
              console.log("success");
            } else {
              console.log("false");
            }
        });
        return {...mail, folder: id, selected: false,}
      } else 
      {
        return mail;
      }
    }
    );
    message.success("Mail has been moved successfully.");
    this.setState({
      selectedMails: 0,
      allMail: mails,
      noContentFoundMessage: 'No Mail found in selected folder',
      folderMails: mails.filter(mail => mail.folder === this.state.selectedFolder)
    });
  };

  onLabelMenuItemSelect = (e) => {
    var id = +e.key;
    const mails = this.state.allMail.map(mail => {
        if (mail.selected && (mail.folder === this.state.selectedFolder)) {
          if (mail.labels.includes(id)) {
            // return {...mail, labels: this.removeLabel(mail, id)};
            return mail
          } else {
            return {...mail, labels: this.addLabel(mail, id)};
          }
        } else {
          return mail;
        }
      }
    );
    message.success("Label Updated Successfully.");
    this.setState({
      noContentFoundMessage: 'No Mail found in selected label',
      allMail: mails,
      folderMails: mails.filter(mail => mail.folder === this.state.selectedFolder)
    });
  };

  handleRequestClose = () => {
    this.setState({
      composeMail: false,
      showMessage: false,
    });
  };
  getNavFilters = () => {
    return filters.map((filter, index) =>
      <li key={index} onClick={() => {
        const mailLimit = this.state.initalPageNum+","+this.state.totalNumMail;
        var filterMails = "";
        var raw = JSON.stringify({"userId":this.state.user_id,"mailAuthKey":this.state.mailAuthKey,"filterType":filter.id,"mailLimit":mailLimit});
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw
        };
        fetch('https://manage.phoenixads.net/mailbox/get-filter-emails.php', requestOptions)
          .then(response => response.json())
          .then(result => {
            if(result.statusCode === 400){            
              this.userLogOut();
            } else {
              if(result.mails === null){
                filterMails = [];
              }else{
                filterMails = result.mails;
              }
              this.setState({
                selectedFolder: 98,
                noContentFoundMessage: 'No Mail found in selected Label.',
                currentMail: null,
                loader: false,
                folderMails: filterMails,
                allMail: filterMails,
                mails: filterMails,
                showComposeMail: false,
                selectedFilter: filter.id
              });
            }
        })
        this.getfolderWithNum(filter.id, "filter");
        setTimeout(() => {
          this.setState({loader: false});
        }, 100);
      }
      }>
        <span className={`${this.state.selectedFilter === filter.id ? 'active gx-link' : 'gx-link'}`}>
          <i className={`icon icon-${filter.icon}`}/>
          <span>{filter.title}</span>
        </span>
      </li>
    )
  };
  onOptionMenuItemSelect = (e) => {
    switch (e.key) {
      case 'All':
        this.handleRequestClose();
        this.getAllMail();
        break;
      case 'None':
        this.handleRequestClose();
        this.getUnselectedAllMail();
        break;
      case 'Read':
        this.handleRequestClose();
        this.getReadMail();
        break;
      case 'Unread':
        this.handleRequestClose();
        this.getUnreadMail();
        break;
      case 'Starred':
        this.handleRequestClose();
        this.getStarredMail();
        break;
      case 'Unstarred':
        this.handleRequestClose();
        this.getUnStarredMail();
        break;
      case 'Important':
        this.handleRequestClose();
        this.getImportantMail();
        break;
      case 'Unimportant':
        this.handleRequestClose();
        this.getUnimportantMail();
        break;
      default:
        break;
    }
  };

  getAllMail = () => {
    let mails = this.state.allMail.map((mail) => mail.folder === this.state.selectedFolder ? {
      ...mail,
      selected: true
    } : mail);
    this.setState({
      selectedMails: mails.length,
      allMail: mails,
      optionName: 'All',
      folderMails: mails.filter(mail => mail.folder === this.state.selectedFolder)
    });
  };
  getUnselectedAllMail = () => {
    let mails = this.state.allMail.map((mail) => mail.folder === this.state.selectedFolder ? {
      ...mail,
      selected: false
    } : mail);
    this.setState({
      selectedMails: 0,
      allMail: mails,
      optionName: 'None',
      folderMails: mails.filter(mail => mail.folder === this.state.selectedFolder)
    });
  };
  getReadMail = () => {
    let selectedMail = 0;
    let mails = this.state.allMail.filter(mail => mail.folder === this.state.selectedFolder);
    mails = mails.map((mail) => {
      if (mail.read) {
        selectedMail++;
        return {...mail, selected: true};
      }
      return {...mail, selected: false}
    });
    this.setState({
      selectedMails: selectedMail,
      optionName: 'Read',
      folderMails: mails.filter(mail => mail.folder === this.state.selectedFolder)
    });
    return mails;
  };
  getUnreadMail = () => {
    let selectedMail = 0;
    let mails = this.state.allMail.filter(mail => mail.folder === this.state.selectedFolder);
    mails = mails.map((mail) => {
      if (!mail.read) {
        selectedMail++;
        return {...mail, selected: true};
      }
      return {...mail, selected: false}
    });
    this.setState({
      selectedMails: selectedMail,
      optionName: 'Unread',
      folderMails: mails.filter(mail => mail.folder === this.state.selectedFolder)
    });
    return mails;
  };
  getStarredMail = () => {
    let selectedMail = 0;
    let mails = this.state.allMail.filter(mail => mail.folder === this.state.selectedFolder);
    mails = mails.map((mail) => {
      if (mail.starred) {
        selectedMail++;
        return {...mail, selected: true};
      }
      return {...mail, selected: false}
    });
    this.setState({
      selectedMails: selectedMail,
      optionName: 'Starred',
      folderMails: mails.filter(mail => mail.folder === this.state.selectedFolder)
    });
    return mails;
  };
  getUnStarredMail = () => {
    let selectedMail = 0;
    let mails = this.state.allMail.filter(mail => mail.folder === this.state.selectedFolder && mail.folder === this.state.selectedFolder);
    mails = mails.map((mail) => {
      if (!mail.starred) {
        selectedMail++;
        return {...mail, selected: true};
      }
      return {...mail, selected: false}
    });
    this.setState({
      selectedMails: selectedMail,
      optionName: 'UnStarred',
      folderMails: mails.filter(mail => mail.folder === this.state.selectedFolder)
    });
    return mails;
  };
  getImportantMail = () => {
    let selectedMail = 0;
    let mails = this.state.allMail.filter(mail => mail.folder === this.state.selectedFolder && mail.folder === this.state.selectedFolder);
    mails = mails.map((mail) => {
      if (mail.important) {
        selectedMail++;
        return {...mail, selected: true};
      }
      return {...mail, selected: false}
    });
    this.setState({
      selectedMails: selectedMail,
      optionName: 'Important',
      folderMails: mails.filter(mail => mail.folder === this.state.selectedFolder)
    });
    return mails;
  };
  getUnimportantMail = () => {
    let selectedMail = 0;
    let mails = this.state.allMail.filter(mail => mail.folder === this.state.selectedFolder && mail.folder === this.state.selectedFolder);
    mails = mails.map((mail) => {
      if (!mail.important) {
        selectedMail++;
        return {...mail, selected: true};
      }
      return {...mail, selected: false}
    });
    this.setState({
      selectedMails: selectedMail,
      optionName: 'Unimportant',
      noContentFoundMessage: 'No Mail found in selected Label',
      folderMails: mails.filter(mail => mail.folder === this.state.selectedFolder)
    });
    return mails;
  };
  getNavLabels = () => {
    return this.state.labels && this.state.labels.map((label, index) =>
      <li key={index} onClick={() => {
        const mailLimit = this.state.initalPageNum+","+this.state.totalNumMail;
        this.getfolderWithNum(99, label.labelId);
        var filterMails = "";
        var raw = JSON.stringify({"userId":this.state.user_id,"mailAuthKey":this.state.mailAuthKey,"labelId":label.labelId,"mailLimit":mailLimit});
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,

        };
        fetch('https://manage.phoenixads.net/mailbox/get-label-emails.php', requestOptions)
          .then(response => response.json())
          .then(result => {
            if(result.statusCode === 400){            
              this.userLogOut();
            } else {
              if(result.mails === null){
                filterMails = [];
              }else{
                filterMails = result.mails;
              }
              this.setState({
                selectedFolder: 99,
                noContentFoundMessage: 'No Mail found in selected Label.',
                currentMail: null,
                loader: false,
                folderMails: filterMails,
                allMail: filterMails,
                mails: filterMails,
                showComposeMail: false,
                selectLabelId: label.labelId,
                selectedFilter: 0
              });
            }
        })
      }
      }>
        <span className={`${this.state.selectLabelId === label.labelId && this.state.selectedFolder === 99 ? 'active gx-link' : 'gx-link'}`}>
          <i className={`icon icon-tag gx-text-success`}/>
          <span>{label.labelName}</span>
        </span>
      </li>
    )
  };

  searchMail = (searchText) => {
    if (searchText === '') {
      this.allMessageslist(this.state.selectedFolder);
      this.setState({searchingMail: false, totalMails: this.state.prevtotalMails});
    } else {
      var filterMails = "";
      var raw = JSON.stringify({"userId":this.state.user_id,"mailAuthKey":this.state.mailAuthKey,"folderId":this.state.selectedFolder,"qstr":searchText,"labelId":this.state.selectLabelId});
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw
      };
      fetch('https://manage.phoenixads.net/mailbox/mail-search.php', requestOptions)
        .then(response => response.json())
        .then(result => {
          if(result.mails === null){
            filterMails = [];
          }else{
            filterMails = result.mails;
          }
          this.setState({
            totalMails: filterMails.length,
            folderMails: filterMails,
            searchingMail: true,
            currentMail: null
          });
      })
    }
  };

  displayMail = (currentMail, folderMails, noContentFoundMessage) => {
    return (<div className="gx-module-box-column">
      {currentMail === null ?
        folderMails.length === 0 ?
          <div className="gx-no-content-found gx-text-light gx-d-flex gx-align-items-center gx-justify-content-center">
            {noContentFoundMessage}
          </div>
          :
          <MailList mails={folderMails} labels={this.state.labels} onStartSelect={this.onStartSelect.bind(this)}
                    onMailSelect={this.onMailSelect.bind(this)} onMailChecked={this.onMailChecked.bind(this)}/>
        :
        <MailDetail mail={currentMail} onStartSelect={this.onStartSelect.bind(this)} onSelectDelete={this.onSelectDelete.bind(this)}
                    onImportantSelect={this.onImportantSelect.bind(this)} labels={this.state.labels} readMail={this.readMail.bind(this)}/>}
    </div>)
  };
  getMailActions = () => {
    return <div className="gx-flex-row gx-align-items-center">

      <Dropdown overlay={this.folderMenu()} placement="bottomRight" trigger={['click']}>
        <i className="icon icon-folder gx-icon-btn"/>
      </Dropdown>

      <Dropdown overlay={this.labelMenu()} placement="bottomRight" trigger={['click']}>
        <i className="icon icon-tag gx-icon-btn"/>
      </Dropdown>

      <span onClick={this.onDeleteMail.bind(this)}>
        <i className="icon icon-trash gx-icon-btn"/></span>

    </div>
  };

  optionMenu = () => (
    <Menu id="option-menu"
          onClick={this.onOptionMenuItemSelect.bind(this)}>
      {options.map(option =>
        <Menu.Item key={option.title}>
          {option.title}
        </Menu.Item>,
      )}
    </Menu>);

  folderMenu = () => (
    <Menu id="folder-menu"
          onClick={this.onFolderMenuItemSelect.bind(this)}>
        {this.state.selectedFolder === 3 ? <Menu.Item key="0">
          Inbox
        </Menu.Item> : <Menu.Item key="3">
          Trash
        </Menu.Item> }
    </Menu>);

  labelMenu = () => (
    <Menu id="label-menu"
          onClick={this.onLabelMenuItemSelect.bind(this)}>
      {this.state.userLabels && this.state.userLabels.map(label =>
        <Menu.Item key={label.labelId}>
          {label.labelName}
        </Menu.Item>,
      )}
    </Menu>
  );

  constructor() {
    super();
    this.state = {
      mails: '',
      searchMail: '',
      noContentFoundMessage: 'No Mail found in selected folder',
      alertMessage: '',
      showMessage: false,
      drawerState: false,
      optionName: 'None',
      anchorEl: null,
      allMail: '',
      loader: true,
      currentMail: null,
      user: {
        name: 'Robert Johnson',
        email: 'robert@example.com',
        avatar: 'https://via.placeholder.com/150x150'
      },
      selectedMails: 0,
      selectedFolder: 0,
      composeMail: false,
      showComposeMail: false,
      folderMails: '',
      user_id: '',
      allFolderMails: '',
      labels: [],
      respSuccess: '',
      initalPageNum: 0,
      totalNumMail:50,
      currentMailPage: 1,
      selectLabelId: 0,
      addLabelmodal: false,
      loading: false,
      addlabel:'',
      searchingMail: false,
      lastMailId: null,
      totalMails: 0,
      mailAuthKey: null,
      prevtotalMails: 0,
      selectedFilter : 0,
      userLabels: []
    };
    this.showCompose = this.showCompose.bind(this);
  }

  showCompose() {
    this.setState(prevState => ({
      showComposeMail: !prevState.showComposeMail
    }));
  }

 getfolderWithNum(folderId, labelId){
  var raw11 = "";
   if (labelId === "folder") {
    raw11 = JSON.stringify({"userId":this.state.user_id,"mailAuthKey":this.state.mailAuthKey,"folderId":folderId});
   } else if(labelId === "filter") {
    raw11 = JSON.stringify({"userId":this.state.user_id,"mailAuthKey":this.state.mailAuthKey,"folderId":98, "filterType":folderId});
   } else {
    raw11 = JSON.stringify({"userId":this.state.user_id,"mailAuthKey":this.state.mailAuthKey,"folderId":folderId, "labelId":labelId});
   }
    var requestOptions11 = {
      method: 'POST',
      headers: myHeaders,
      body: raw11,
    };
    fetch('https://manage.phoenixads.net/mailbox/mails-count.php', requestOptions11)
      .then(response => response.json())
      .then(result => {
        if (result.status === 200) {
          this.setState({totalMails: result.count, prevtotalMails: result.count})
        } 
      })
 }
  
  componentDidMount() {
    const userid = localStorage.getItem('user_id');
    const mailAuthKey = localStorage.getItem('mailAuthKey');
    const mailLimit = this.state.initalPageNum+","+this.state.totalNumMail;
    this.setState({ user_id: userid, mailAuthKey: mailAuthKey });
    var filterMails = "";
    var raw = JSON.stringify({"userId":userid,"mailAuthKey":mailAuthKey,"folder":"0","mailLimit":mailLimit});
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };
    fetch('https://manage.phoenixads.net/mailbox/get-user-emails.php', requestOptions)
      .then(response => response.json())
      .then(result => {
        this.setState({loader: false});
        if(result.statusCode === 400){
          this.userLogOut();
        } else {
          if(result.mails === null){
            filterMails = [];
          }else{
            filterMails = result.mails;
            this.setState({lastMailId:result.mails[0].mailLogId});
          }
          this.getfolderWithNum(0, "folder");
          this.setState({mails: filterMails, allMail: filterMails, folderMails: filterMails.filter(mail => mail.folder === 0)});
      }
    })
    var raw1 = JSON.stringify({userId: userid, "mailAuthKey":mailAuthKey});
    var requestOptions1 = {
      method: 'POST',
      headers: myHeaders,
      body: raw1
    };
    var allLabelsList = [];
    fetch('https://manage.phoenixads.net/mailbox/get-user-labels.php', requestOptions1)
      .then(response => response.json())
      .then(result => {
        if(result.statusCode === 400){            
          this.userLogOut();
        } else {
          if (result.labels === null) {
            allLabelsList = fixlabels;
          } else {
            allLabelsList = result.labels.concat(fixlabels);
          }
          this.setState({labels:allLabelsList, userLabels: result.labels});
        }
    })
    this.mailListRefreshTime = setInterval(
      () => this.messagesUpdate(0),
      15000
    );
  }

  componentWillUnmount() {
    clearInterval(this.mailListRefreshTime);
  }

  messagesUpdate(folderId) {
    if(this.state.selectedFolder === 0 && this.state.searchingMail === false){
      var lastMailId = "";
      var filterMails = "";
      var raw = JSON.stringify({"userId":this.state.user_id,"mailAuthKey":this.state.mailAuthKey,"folder":folderId,"mailLimit":"0,50"});
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw
      };
      fetch('https://manage.phoenixads.net/mailbox/get-user-emails.php', requestOptions)
        .then(response => response.json())
        .then(result => {
          if(result.statusCode === 400){            
            
            this.userLogOut();
          } else {
            if(result.mails === null){
              filterMails = [];
            }else{
              filterMails = result.mails;
              lastMailId = result.mails[0].mailLogId;
            }
            if(lastMailId !== "") {
              if(this.state.lastMailId !== lastMailId) {
                this.setState({
                  folderMails: filterMails,
                  lastMailId: lastMailId
                });
              }
            }
          }
      })
    } else {
      this.setState({
        folderMails: this.state.folderMails
      });
    }
  }

  allMessageslist(folderId) {
    var filterMails = "";
    var raw = "";
    var api_Url = "";
    if(this.state.selectedFolder === 99){
      api_Url = "https://manage.phoenixads.net/mailbox/get-label-emails.php";
      raw = JSON.stringify({"userId":this.state.user_id,"mailAuthKey":this.state.mailAuthKey,"labelId":this.state.selectLabelId,"mailLimit":"0,50"});
    } else {
      api_Url = "https://manage.phoenixads.net/mailbox/get-user-emails.php";
      raw = JSON.stringify({"userId":this.state.user_id,"mailAuthKey":this.state.mailAuthKey,"folder":folderId,"mailLimit":"0,50"});
    }
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw
      };
      fetch(api_Url, requestOptions)
        .then(response => response.json())
        .then(result => {
          if(result.statusCode === 400){
            
            this.userLogOut();
          }else{
            if(result.mails === null){
              filterMails = [];
            }else{
              filterMails = result.mails;
            }
            this.setState({
              folderMails: filterMails
            });
          }
      })
  }

  onMailChecked(data) {
    data.selected = !data.selected;
    let selectedMail = 0;
    const mails = this.state.folderMails.map(mail => {
        if (mail.selected) {
          selectedMail++;
        }
        if (mail.id === data.id) {
          if (mail.selected) {
            selectedMail++;
          }
          return data;
        } else {
          return mail;
        }
      }
    );
    
    this.setState({
      selectedMails: selectedMail,
      folderMails: mails
    });
  }

  onAllMailSelect() {
    const selectAll = this.state.selectedMails < this.state.folderMails.length;
    if (selectAll) {
      this.getAllMail();
    } else {
      this.getUnselectedAllMail();
    }
  }

  removeLabel(mail, label) {
    mail.labels.splice(mail.labels.indexOf(label), 1);
    if (this.state.currentMail !== null && mail.id === this.state.currentMail.id) {
      this.setState({
        currentMail: {...mail, labels: mail.labels}
      })
    }
    return mail.labels;
  }

  userLogOut = () => {
    message.error("Please login manage.");
    localStorage.removeItem('username');
    localStorage.removeItem('user_id');
    setTimeout(function(){ window.location.href = "/"; }, 2000);
  }

  onStartSelect(data) {
    data.starred = !data.starred;
    var raw = JSON.stringify({"userId":this.state.user_id,"mailAuthKey":this.state.mailAuthKey,"mailLogId":data.mailLogId});
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw
    };
    fetch('https://manage.phoenixads.net/mailbox/toggle-starred-mail.php', requestOptions)
      .then(response => response.json())
      .then(result => {
        if(result.status === "Success"){
          message.success(result.message);
          this.setState({
            folderMails: this.state.folderMails.map(mail =>
              mail.id === data.id ?
                data : mail
            )
          });
        }else{
          message.error(result.message);
        }
    })
    return null;
  }
  readMail(data){
    data.read = !data.read;
    var raw = JSON.stringify({"userId":this.state.user_id,"mailAuthKey":this.state.mailAuthKey,"mailLogId":data.mailLogId});
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw
    };
    fetch('https://manage.phoenixads.net/mailbox/move-mail-read.php', requestOptions)
      .then(response => response.json())
      .then(result => {
        if(result.statusCode === "200"){
          this.setState({
            folderMails: this.state.folderMails.map(mail =>
              mail.id === data.id ?
                data : mail
            )
          });
        }
    })
    return null;
  }
  onImportantSelect(data) {
    data.important = !data.important;
    var raw = JSON.stringify({"userId":this.state.user_id,"mailAuthKey":this.state.mailAuthKey,"mailLogId":data.mailLogId});
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw
    };
    fetch('https://manage.phoenixads.net/mailbox/toggle-important-mail.php', requestOptions)
      .then(response => response.json())
      .then(result => {
        if(result.status === "Success"){
          message.success(result.message);
          this.setState({
            folderMails: this.state.folderMails.map(mail =>
              mail.id === data.id ?
                data : mail
            )
          });
        }else{
          message.error(result.message);
        }
    })
  }

  onMailSelect(mail) {
    this.setState({
      loader: true,
      currentMail: mail,
    });
    setTimeout(() => {
      this.setState({loader: false});
    }, 20);
  }

  addLabel(mail, label) {
    var raw = JSON.stringify({"userId":this.state.user_id,"mailAuthKey":this.state.mailAuthKey,"mailLogId":mail.mailLogId, "labelId": label});
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw
    };
    fetch('https://manage.phoenixads.net/mailbox/apply-user-labels.php', requestOptions)
      .then(response => response.json())
      .then(result => {
        if(result.status === "Success"){
          if (this.state.currentMail !== null && mail.id === this.state.currentMail.id) {
            this.setState({
              currentMail: {...mail, labels: mail.labels.concat(label)}
            })
          }
        } else {
          message.error(result.message);
        }
    })
    return mail.labels.concat(label)
  }

  updateSearch = (evt) => {
    this.setState({
      searchMail: evt.target.value
    });
  }

  updateSearchCall = (evt) => {
    if (evt.key === "Enter") {
      this.searchMail(this.state.searchMail)
    }
  }

  onToggleDrawer() {
    this.setState({
      drawerState: !this.state.drawerState
    });
  }

  render() {
    const {selectedMails, loader, currentMail, drawerState, folderMails,labels,userLabels,alertMessage, showMessage, noContentFoundMessage, loading, visible} = this.state;
    return (

      <div className="gx-main-content">
        <div className="gx-app-module">

          <div className="gx-d-block gx-d-lg-none">
            <Drawer
              placement="left"
              closable={false}
              visible={drawerState}
              onClose={this.onToggleDrawer.bind(this)}>
              {this.MailSideBar()}
            </Drawer>
          </div>
          <div className="gx-module-sidenav gx-d-none gx-d-lg-flex">
            {this.MailSideBar()}
          </div>
          <div className="gx-module-box">
            <div className="gx-module-box-header">
              <span className="gx-drawer-btn gx-d-flex gx-d-lg-none">
                  <i className="icon icon-menu gx-icon-btn" aria-label="Menu"
                     onClick={this.onToggleDrawer.bind(this)}/>
              </span>
              <AppModuleHeader placeholder="Press enter to search mails" user_id={this.state.user_id} labels={labels}
                               onChange={this.updateSearch.bind(this)}
                               onKeyPress={this.updateSearchCall.bind(this)}
                               value={this.state.searchMail}/>

            </div>
            <div className="gx-module-box-content">
            {this.state.showComposeMail ? <ComposeMail /> : 
            <div>
              <div className="gx-module-box-topbar">
                {this.state.currentMail === null ?
                  <div className="gx-flex-row gx-align-items-center m-topbar">
                    {this.state.folderMails.length > 0 ?
                      <Auxiliary>
                          <div className="w-50 w-actions">
                            <Checkbox color="primary" className="gx-icon-btn"
                                      indeterminate={selectedMails > 0 && selectedMails < folderMails.length}
                                      checked={selectedMails > 0}
                                      onChange={this.onAllMailSelect.bind(this)}
                                      value="SelectMail"/>

                            <Dropdown overlay={this.optionMenu()} placement="bottomRight" trigger={['click']}>
                              <div>
                                <span className="gx-px-2"> {this.state.optionName}</span>
                                <i className="icon icon-charvlet-down"/></div>
                            </Dropdown>
                            {(selectedMails > 0) && this.getMailActions()}
                          </div>
                          <div className="w-50 w-pagination">
                              <span className="totalmails">{this.state.totalMails} mails</span>
                              <ButtonGroup>
                              <Button type="primary" onClick={this.previousMail}>
                                <Icon type="left"/>
                              </Button>
                              <Button type="primary" onClick={this.nextMail}>
                                <Icon type="right"/>
                              </Button>
                            </ButtonGroup>
                          </div>
                      </Auxiliary>
                      : null}
                  </div>
                  :
                  <i className="icon icon-arrow-left gx-icon-btn" onClick={() => {
                    this.setState({currentMail: null})
                  }}/>
                }

                <div classID="toolbar-separator"/>
              </div>
              {loader ?
                <div className="gx-loader-view">
                  <CircularProgress/>
                </div>
                : this.displayMail(currentMail, folderMails, noContentFoundMessage)}
              </div>
            }
            </div>
          </div>
          <Modal
            visible={visible}
            title="My Labels"
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
              <Button type="primary" key="add" onClick={() => this.setAddLabelmodal(true)}>Add Label</Button>,
            ]}
          >
            {
                userLabels &&
                userLabels.length > 0 &&
                userLabels.map((item)=>{
                  return <li key={item.labelName}>
                  <span className="label-span">{item.labelName}</span> <button onClick={this.deleteLabel.bind(this, item)} className="delete-button"><i className="icon icon-trash gx-icon-btn"/></button>
                </li>
              })
              }
          </Modal>

          <Modal
            title="Add New Label"
            wrapClassName="vertical-center-modal"
            visible={this.state.addLabelmodal}
            onOk={() =>this.handleAddLabel()}
            onCancel={() => this.setAddLabelmodal(false)}
            footer={[
              <Button key="submit" type="primary" loading={loading} onClick={() =>this.handleAddLabel()}>
                Add
              </Button>
            ]}
          >
            <Form>
              <Input
                  placeholder="Label Name"
                  margin="normal"
                  name="labelName"
                  value= {this.state.addlabel}
                  onChange={(e) =>this.handlechangeAddLabel(e.target.value)}
                />
            </Form>
          </Modal>
        </div>
        {showMessage && message.info(<span id="message-id">{alertMessage}</span>, 3, this.handleRequestClose)}
      </div>
    )
  }
}

export default Mail;
